'use client'
import { Session } from '@supabase/supabase-js'
import React from 'react'
import { Button, linkButtonProps } from '~/components/ui/button'
import { useSupabase } from './client'

// for the `session` to be available on first SSR render, it must be fetched in a Server Component and passed down as a prop

// https://github.com/supabase/supabase/blob/master/examples/auth/nextjs/app/login-form.tsx

export function AuthUIClient(props: { session: Session | null }) {
  const { session } = useSupabase(props)

  const isProd = process.env.NEXT_PUBLIC_APP_URL?.includes('verbalist.app')

  if (session) {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <div {...linkButtonProps} data-user-email={session.user.email} style={{ textDecoration: 'none' }}>
          {session.user.email}
        </div>
        <form action="/auth/signout" method="post">
          <button {...linkButtonProps} style={{ width: '80px' }}>
            Sign out
          </button>
        </form>
        {!isProd && (
          <a {...linkButtonProps} href="/auth/local">
            Sign in locally
          </a>
        )}
      </div>
    )
  }

  return (
    <>
      <form action="/auth/signin" method="post">
        <Button variant="link" size="sm">
          Sign in with Github
        </Button>
      </form>
      {!isProd && (
        <a {...linkButtonProps} href="/auth/local">
          Sign in locally
        </a>
      )}
    </>
  )
}
